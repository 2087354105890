import { useEffect, useState } from 'react';
import { SubTransaction, WatteryReceipt } from '../../Model/Receipt';
import { getReceipt } from '../../Services/Stripe';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { CurrencyMap } from '../../Utilities/CurrencyMap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Margin, usePDF } from 'react-to-pdf';
import { StyledReceipt } from './Receipt.styled';
import { Button } from 'react-bootstrap';

import { PricingType } from '../../Model/Chargepoint';
import { TableRow } from '../../Utilities/TableShorthand';
import {
  formatCardIssuer,
  formatSubTransaction,
  getVatAmount,
  toLocalePrice,
} from '../../Utilities/UtilityFunctions';
import ErrorPage from '../ErrorPage/ErrorPage';
import { TranslateButton } from '../General/Language';

export default function Receipt() {
  const [receipt, setReceipt] = useState<WatteryReceipt>();
  const transactionUUID = useParams<{ unique_id: string }>().unique_id;
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [showView, setShowView] = useState(0);
  const [cardAsterisks] = useState(
    Array(4).join(`${Array(5).join('\u2217')} `)
  );
  const { t } = useTranslation('common', { i18n: i18n });

  function formatSpotPriceDates(subTransactions: SubTransaction[]) {
    for (const spotPrice of subTransactions) {
      spotPrice.startTime = format(
        new Date(spotPrice.startTime),
        'dd.MM.yyyy HH:mm'
      );
    }
  }

  useEffect(() => {
    async function getData(id: string) {
      const r = await getReceipt(id);
      if (r.isSuccess()) {
        const a = r.response;
        setReceipt(a);
        const d = new Date(a.startTime);
        const d1 = new Date(a.endTime);
        setStart(format(new Date(d), 'dd.MM.yyyy HH:mm:ss'));
        setEnd(format(new Date(d1), 'dd.MM.yyyy HH:mm:ss'));
        if (a.pricingType === PricingType.SPOT) {
          formatSpotPriceDates(a.subTransactions);
        }
        setShowView(1);
      } else {
        setShowView(0);
        //log error or display it or smth
      }
    }
    if (transactionUUID !== undefined) {
      getData(transactionUUID);
    }
    //eslint-disable-next-line
  }, []);

  const { toPDF, targetRef } = usePDF({
    filename: `Kuitti_${format(new Date(), 'ddMMyyyy')}.pdf`,
    page: {
      margin: Margin.LARGE,
      format: 'A4',
    },
  });

  const cardBrand = formatCardIssuer(receipt?.cardInfo);

  return (
    <>
      {showView === 0 ? (
        <h2 className="align-self-center">{t('global.view.loading')}</h2>
      ) : showView === -1 ? (
        <h2 className="align-self-center">{t('global.view.error')}</h2>
      ) : !!receipt && receipt.invoiceStatus === 2 ? (
        <>
          <TranslateButton />
          <StyledReceipt ref={targetRef}>
            <table style={{ width: '100%' }}>
              <tbody>
                <TableRow
                  header={`${t('components.receipt.documentTitle')} ${
                    receipt.receiptNumber
                  }`}
                  hColSpan={3}
                />
                <TableRow
                  header={t('components.receipt.site')}
                  data={[receipt.reportingSite]}
                  styling="append"
                  rowStyle={{
                    borderTop: '1pt solid black',
                    borderBlockWidth: '100%',
                  }}
                  dColSpan={2}
                />
                <TableRow
                  header={t('components.receipt.startTime')}
                  data={[start]}
                  dColSpan={2}
                />
                <TableRow
                  header={t('components.receipt.endTime')}
                  data={[end]}
                  dColSpan={2}
                />
                <TableRow
                  header={`${t('components.receipt.quantity')}, kWh`}
                  data={receipt.kWh}
                  styling="append"
                  hColSpan={2}
                  dataFormatter={toLocalePrice}
                />
                {receipt.cardInfo?.last4 !== undefined ? (
                  <>
                    <TableRow
                      header={t('components.receipt.paymentMethod')}
                      hColSpan={3}
                    />
                    <TableRow
                      header={
                        cardBrand !== null ? (
                          <img
                            src={cardBrand.src}
                            alt={cardBrand.alt}
                            style={{ maxHeight: '3em' }}
                          />
                        ) : (
                          t('global.general.card')
                        )
                      }
                      data={`${cardAsterisks} ${receipt.cardInfo.last4}`}
                      dColSpan={2}
                      styling="append"
                      rowStyle={{ borderBottom: '1em solid white' }}
                    />
                  </>
                ) : null}

                <TableRow
                  header={`${t('components.receipt.seller')}: ${
                    receipt.seller
                  }`}
                  styling="custom"
                  rowStyle={{ textAlign: 'left' }}
                  hColSpan={3}
                />
                <TableRow
                  data={`${t('components.receipt.businessId')}: ${
                    receipt.businessId
                  }`}
                  styling="custom"
                  rowStyle={{ textAlign: 'left' }}
                />
                <TableRow
                  data={`${t('components.startTransaction.address')}: 
                      ${`${receipt.address}, ${receipt.country}-${receipt.zip} ${receipt.city}`}`}
                  styling="custom"
                  rowStyle={{ textAlign: 'left' }}
                  dColSpan={3}
                />
                {receipt?.pricingType === PricingType.SPOT ? (
                  <>
                    <TableRow
                      header={t(
                        'components.startTransaction.spotPrices.header'
                      )}
                      styling="append"
                      rowStyle={{
                        borderTop: '1pt solid black',
                        borderBlockWidth: '100%',
                      }}
                      hColSpan={3}
                    />

                    {receipt.subTransactions.map((subTransaction) => {
                      return (
                        <TableRow
                          key={subTransaction.startTime}
                          header={subTransaction.startTime}
                          headerType="data"
                          data={formatSubTransaction(subTransaction)}
                        />
                      );
                    })}
                    <TableRow
                      header={`${t('components.receipt.subtotal')} 
                      ${CurrencyMap[receipt.currency]}`}
                      data={receipt.transactionFee}
                      styling="append"
                      rowStyle={{
                        borderTop: '1pt solid black',
                      }}
                      hColSpan={2}
                      dataFormatter={toLocalePrice}
                    />
                    <TableRow
                      headerType="data"
                      header={`${t('components.receipt.whereVatPercent', {
                        VAT: receipt.subTransactions[0].price.vatRate,
                      })}`}
                      data={getVatAmount(
                        receipt.transactionFee,
                        receipt.subTransactions[0].price.vatRate
                      )}
                      styling="append"
                      rowStyle={{
                        borderBottom: '1em solid white',
                      }}
                      hColSpan={2}
                      dataFormatter={toLocalePrice}
                    />
                  </>
                ) : (
                  <>
                    <TableRow
                      header={`${t('components.receipt.startPrice')}, 
                          ${CurrencyMap[receipt.currency]}`}
                      data={receipt.startPrice}
                      hColSpan={2}
                      headerType="data"
                      styling="append"
                      rowStyle={{
                        borderTop: '1pt solid black',
                        borderBlockWidth: '100%',
                      }}
                      dataFormatter={toLocalePrice}
                    />
                    <TableRow
                      header={t('components.receipt.unitPrice')}
                      data={[
                        `${toLocalePrice(receipt.price)} ${
                          CurrencyMap[receipt.currency]
                        }/kWh`,
                        String(toLocalePrice(receipt.price * receipt.kWh)),
                      ]}
                      hColSpan={1}
                      headerType="data"
                    />
                    <TableRow
                      header={`${t('components.receipt.subtotal')} 
                      ${CurrencyMap[receipt.currency]}`}
                      data={receipt.transactionFee}
                      styling="append"
                      rowStyle={{
                        borderTop: '1pt solid black',
                        borderBottom: receipt.vat > 0 ? '' : '1em solid white',
                      }}
                      hColSpan={2}
                      dataFormatter={toLocalePrice}
                    />
                    {receipt.vat > 0 ? (
                      <TableRow
                        headerType="data"
                        header={`${t('components.receipt.whereVatPercent', {
                          VAT: receipt.vat,
                        })}`}
                        data={getVatAmount(receipt.transactionFee, receipt.vat)}
                        styling="append"
                        rowStyle={{
                          borderBottom: '1em solid white',
                        }}
                        hColSpan={2}
                        dataFormatter={toLocalePrice}
                      />
                    ) : null}
                  </>
                )}
                <TableRow
                  header={`${t('components.receipt.seller')}: Wattery Oy`}
                  hColSpan={3}
                />
                <TableRow
                  headerType="data"
                  header={`${t('components.receipt.businessId')}: 3421963-3`}
                  hColSpan={3}
                />
                <TableRow
                  headerType="data"
                  header={`${t(
                    'components.startTransaction.address'
                  )}: Tilhenkatu 23, FI-20610 Turku`}
                  hColSpan={3}
                />
                <TableRow
                  header={`${t('components.receipt.serviceFee')} 
                      ${CurrencyMap[receipt!.currency]}`}
                  data={receipt.serviceFee}
                  styling="append"
                  rowStyle={{ borderTop: '1pt solid black' }}
                  hColSpan={2}
                  dataFormatter={toLocalePrice}
                />
                <TableRow
                  headerType="data"
                  header={`${t('components.receipt.whereVatPercent', {
                    VAT: receipt.serviceFeeVat,
                  })}`}
                  data={getVatAmount(receipt.serviceFee, receipt.serviceFeeVat)}
                  styling="append"
                  rowStyle={{
                    borderBottom: '1em solid white',
                  }}
                  hColSpan={2}
                  dataFormatter={toLocalePrice}
                />

                <TableRow
                  header={`${t('components.receipt.total')} 
                      ${CurrencyMap[receipt.currency]}`}
                  data={receipt.totalPrice}
                  styling="append"
                  hColSpan={2}
                  dataFormatter={toLocalePrice}
                />
                <TableRow
                  headerType="data"
                  header={`${t('components.receipt.whereVatTotal', {
                    VAT: receipt.vat,
                  })}`}
                  data={
                    receipt.pricingType === PricingType.FIXED
                      ? getVatAmount(receipt.transactionFee, receipt.vat) +
                        getVatAmount(receipt.serviceFee, receipt.serviceFeeVat)
                      : getVatAmount(
                          receipt.transactionFee,
                          receipt.subTransactions[0].price.vatRate
                        ) +
                        getVatAmount(receipt.serviceFee, receipt.serviceFeeVat)
                  }
                  styling="append"
                  rowStyle={{ borderBottom: '1em solid white' }}
                  hColSpan={2}
                  dataFormatter={toLocalePrice}
                />
              </tbody>
            </table>
          </StyledReceipt>
          <div style={{ textAlign: 'left' }} className="mt-3">
            <Button onClick={() => toPDF()}>
              {t('global.buttons.downloadPdf')}
            </Button>
          </div>
        </>
      ) : (
        <>
          {!!receipt && receipt.invoiceStatus !== 4 ? (
            <ErrorPage message={t('global.alert.failure.noReceipt')} />
          ) : !!receipt ? (
            <ErrorPage message={t('global.alert.failure.cancelledPayment')} />
          ) : null}
        </>
      )}
    </>
  );
}
