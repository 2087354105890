import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import loadingIcon from '../../resources/images/loadingIcon.svg';
import i18n from '../../i18n';
import { useEffect, useState } from 'react';
import { emailRegex } from '../../Utilities/Regex';
import {
  StripeElementLocale,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { Chargepoint } from '../../Model/Chargepoint';
import {
  chargePointIsConnected,
  remoteStartTransaction,
} from '../../Services/Transactions';
import { useNavigate } from 'react-router';
import {
  StateHandler,
  separatedCpIdToSingleString,
} from '../../Utilities/Types';
import { DefaultStandaloneField } from '../General/ValidatableField';
import { CpNumber } from '../Start/StartTransaction';
import { Link } from 'react-router-dom';
import { CurrencyMap } from '../../Utilities/CurrencyMap';
import {
  dismissToasts,
  showToast,
  resolveStripeAmount,
  resolveDisplayReservation,
} from '../../Utilities/UtilityFunctions';
import { toast } from 'react-toastify';
import { createPaymentData } from '../../Services/Stripe';
import { PaymentData } from '../../Model/Stripe';
import { WatteryError } from '../../Model/Error';

function Payment(props: props) {
  const {
    cpValidate,
    selectedChargepoint,
    cpId,
    wait,
    backgroundTasks,
    currency,
    setBackgroundTasks,
  } = props;
  const [email, setEmail] = useState('');

  const [paymentData, setPaymentData] = useState<PaymentData>({
    email: email,
    currency: currency,
  });
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [validPm, setValidPm] = useState(false);

  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation('common', { i18n: i18n });

  useEffect(() => {
    elements?.update({
      locale: i18n.resolvedLanguage as StripeElementLocale,
    });
    //eslint-disable-next-line
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    elements?.update({
      currency: currency.toLowerCase(),
      amount: resolveStripeAmount(currency),
    });
    //eslint-disable-next-line
  }, [currency]);

  useEffect(() => {
    setPaymentData((p) => {
      return { ...p, email: email };
    });
  }, [email]);

  async function handleSubmit() {
    dismissToasts();
    if (!(stripe && elements)) {
      return;
    }

    if (email.length > 0 && !emailRegex.test(email)) {
      showToast(t, 'error', 'email');
      return;
    }

    if (!selectedChargepoint) return;

    //Submit stripe elements to create the payment method later
    const e = await elements.submit();
    if (e.error) {
      return;
    }
    const toastWaitForStart = toast.loading(
      t('global.alert.warning.waitForStart')
    );
    setBackgroundTasks(true);
    let tempPaymentData: PaymentData = { ...paymentData };

    if (!paymentIntentId) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements: elements,
      });
      if (error) {
        toast.update(toastWaitForStart, {
          type: 'error',
          render: t('global.alert.failure.generic'),
          autoClose: 15000,
          closeButton: true,
          isLoading: false,
        });
        setBackgroundTasks(false);
        return;
      }
      tempPaymentData.paymentMethodId = paymentMethod.id;

      const paymentDataRes = await createPaymentData(tempPaymentData);
      if (!paymentDataRes.isSuccess()) {
        if (paymentDataRes.message === WatteryError.PAYMENT_PROCESSING) {
          toast.update(toastWaitForStart, {
            type: 'error',
            render: t('global.alert.failure.paymentProcessing'),
            closeButton: true,
            isLoading: false,
          });
        } else {
          toast.update(toastWaitForStart, {
            type: 'error',
            render: t('global.alert.failure.generic'),
            autoClose: 6000,
            closeButton: true,
            isLoading: false,
          });
        }
        setBackgroundTasks(false);
        setPaymentData(paymentDataRes.response);
        setPaymentIntentId(undefined);
        return;
      }
      tempPaymentData = {
        ...paymentDataRes.response,
      };

      const pi = await stripe.confirmCardPayment(tempPaymentData.clientSecret!);

      if (pi.error) {
        toast.update(toastWaitForStart, {
          type: 'error',
          render: t('global.alert.failure.paymentProcessing'),
          closeButton: true,
          isLoading: false,
        });
        setPaymentData({
          ...tempPaymentData,
          paymentMethodId: undefined,
        });
        setBackgroundTasks(false);
        setPaymentIntentId(undefined);
        return;
      }

      setPaymentIntentId(pi.paymentIntent.id);
    }
    const connectedCheck = await chargePointIsConnected(
      Number(cpId.chargepointNumber)
    );

    if (!connectedCheck.isSuccess() || connectedCheck.response === 0) {
      toast.update(toastWaitForStart, {
        type: 'error',
        render: t('global.alert.failure.notConnected', {
          charge_point_id: separatedCpIdToSingleString(cpId),
        }),
        autoClose: 6000,
        closeButton: true,
        isLoading: false,
      });
      setBackgroundTasks(false);
      return;
    }

    const res = await remoteStartTransaction({
      header: 'remotestarttransaction',
      charge_point_id: selectedChargepoint.chargePointId,
      connector_id: Number(cpId.connectorId),
      waitforstart: true,
      paymentData: tempPaymentData,
    });
    setBackgroundTasks(false);

    if (res.isSuccess()) {
      toast.update(toastWaitForStart, {
        type: 'success',
        render: t('global.alert.success.remoteStart'),
        closeButton: true,
        isLoading: false,
      });

      setTimeout(() => navigate(`/active/${res.response.unique_id}`), 2000);
      return;
    }

    switch (res.message) {
      case WatteryError.REJECTED:
        toast.update(toastWaitForStart, {
          type: 'error',
          render: t('global.alert.failure.remoteStart'),
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        setPaymentData(tempPaymentData);
        break;
      case WatteryError.BLOCKED:
        toast.update(toastWaitForStart, {
          type: 'error',
          render: t('global.alert.failure.remoteStart'),
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        setPaymentData(tempPaymentData);
        break;
      case WatteryError.COMMUNICATION:
        toast.update(toastWaitForStart, {
          type: 'warning',
          render: t('global.alert.failure.communication'),
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        setPaymentData(tempPaymentData);
        break;
      default:
        toast.update(toastWaitForStart, {
          type: 'error',
          render: t('global.alert.failure.generic'),
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        setPaymentData(tempPaymentData);
        break;
    }
    setBackgroundTasks(false);
  }

  function onElementsChanged(event: StripePaymentElementChangeEvent) {
    if (event.complete) {
      setValidPm(true);
    } else {
      setValidPm(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={onElementsChanged} />

      <Form.Text>
        {t('components.startTransaction.payment.security')}{' '}
        <a href="https://docs.stripe.com/security">
          {t('global.general.more')}.
        </a>
      </Form.Text>
      <br />
      <br />
      <DefaultStandaloneField
        header={`${t('global.general.email')} (${t(
          'global.general.optional'
        )}, ${t('global.general.forReceipt')})`}
        state={email}
        setState={setEmail}
        type="email"
      />
      <Form.Text>
        {t('components.startTransaction.ehdot')}{' '}
        <Link to="/terms-of-service" target="_blank" rel="noopener noreferrer">
          {`${t('global.terms').toLowerCase()}`}
        </Link>{' '}
        {t('global.general.and')}{' '}
        <a href="https://stripe.com/legal/consumer">
          {' '}
          {t('components.startTransaction.payment.stripe.terms')}
        </a>
      </Form.Text>
      <br />

      <Form.Text>
        {t('components.startTransaction.reservation', {
          amount: resolveDisplayReservation(currency),
          currency: CurrencyMap[currency],
        })}
      </Form.Text>

      <br />
      <br />
      <Button
        disabled={
          !cpValidate.isFormValid ||
          !selectedChargepoint ||
          wait ||
          backgroundTasks ||
          !validPm
        }
        onClick={async () => {
          handleSubmit();
        }}
      >
        {wait || backgroundTasks ? (
          <img
            className="spinner"
            src={loadingIcon}
            alt="Loading"
            style={{
              width: 30,
            }}
          />
        ) : (
          t('global.buttons.start')
        )}
      </Button>
    </form>
  );
}

declare interface props {
  cpValidate: any;
  searching: boolean;
  selectedChargepoint?: Chargepoint;
  cpId: CpNumber;
  wait: boolean;
  setWait: StateHandler<boolean>;
  backgroundTasks: boolean;
  currency: string;
  setBackgroundTasks: StateHandler<boolean>;
}

export default function InjectedCheckoutForm({
  cpValidate,
  searching,
  selectedChargepoint,
  cpId,
  wait,
  setWait,
  backgroundTasks,
  currency,
  setBackgroundTasks,
}: props) {
  return (
    <Payment
      cpValidate={cpValidate}
      searching={searching}
      selectedChargepoint={selectedChargepoint}
      cpId={cpId}
      wait={wait}
      setWait={setWait}
      backgroundTasks={backgroundTasks}
      currency={currency}
      setBackgroundTasks={setBackgroundTasks}
    />
  );
}
